import React, {useState} from "react"
import {Button, Col, FormGroup, Input, Modal, Row} from "reactstrap"

import {TagList} from "./TagList"
import closeTag from '../../../assets/img/close-tag.svg'
import check from '../../../assets/img/check-white.svg'
import classnames from "classnames";
import fetchTools from "../../../services/fetchTools";
import { useTranslation } from 'react-i18next';

export const EditPhotosModal = ({
                                    selectedPhotos = [],
                                    tags = [],
                                    isOpen,
                                    toggleOpen,
                                    toggleSelectMode,
                                    refreshPhotos,
                                    showModalTagsStep
                                }) => {

    let _selectedTags = [],
        _indeterminateTags = [];

    selectedPhotos.forEach(p => {
        p.tags.forEach(t => {
            if (!_selectedTags.find(_t => `${_t._id}` == `${t._id}`)) {
                _selectedTags.push(t)
            }
        })
    })


    _selectedTags.forEach(_t => {
        let push = false
        //check if this tag is exist in all selected photos or not
        selectedPhotos.forEach(p => {
            let tags = p.tags.map(t => t._id)
            if (!tags.includes(_t._id)) {
                push = true;
            }
        })

        if (push) {
            _indeterminateTags.push(_t);
        }
    })

    _selectedTags = _selectedTags.filter(_t => {
        return !_indeterminateTags.find(_it => `${_it._id}` == `${_t._id}`)
    })

    const [selectedTags, setSelectedTags] = useState(_selectedTags)
    const [removeTags, setRemoveTags] = useState([])
    const { t, i18n } = useTranslation();

    const [indeterminateTags, setIndeterminateTags] = useState(_indeterminateTags);
    const [toastMessage, setToastMessage] = useState()
    const [hideMoreSelectedImages, setHideMoreSelectedImages] = useState(true)

    const [showTagsStep, setShowTagsStep] = useState(showModalTagsStep);
    const [showDetailsStep, setShowDetailsStep] = useState(!showModalTagsStep);

    let _photoTitle = selectedPhotos.map(p => p.name).join(';'),
        _photoDescription = selectedPhotos.map(p => p.imageComment).join(';')

    if (selectedPhotos.length > 1) {
        _photoTitle = 'several values'
        _photoDescription = 'several values';
    }

    const [photoTitle, setPhotoTitle] = useState(_photoTitle);
    const [photoDescription, setPhotoDescription] = useState(_photoDescription);

    const goBack = () => {

        if (showTagsStep) {
            setShowTagsStep(false);
            setShowDetailsStep(true);
        }

        if (showDetailsStep) {
            toggleOpen()
        }
    }

    const saveAndSelectTags = async () => {
        await saveDetailsStep(false).then(() => {
            setShowDetailsStep(false)
            setShowTagsStep(true)
        });
    }

    const saveCurrentStep = async () => {
        if (showTagsStep) {
            //save tags.
            await saveTagsStep()
        }

        if (showDetailsStep) {
            //save details step.
            await saveDetailsStep();
        }
    }


    const saveDetailsStep = async (closeModal = true) => {
        const url = `api/images/update-bulk-images`

        return await fetchTools.put(url, {
            imageComment: photoDescription,
            name: photoTitle,
            ids: selectedPhotos.map(p => p._id)
        }).then(response => {
            refreshPhotos(response)

            if (closeModal) {
                setPhotoTitle('');
                setPhotoDescription('');
                toggleOpen();
            }

        }).catch(err => {
            console.log('err= ', err);
        })
    }

    const saveTagsStep = async () => {
        const url = `api/images/update-bulk-images`

        await fetchTools.put(url, {
            tags: selectedTags.map(t => t._id),
            ids: selectedPhotos.map(p => p._id),
            removeTags: removeTags.map(t => t._id)
        }).then(response => {
            refreshPhotos(response)
            setPhotoTitle('');
            setPhotoDescription('');
            toggleOpen();
        }).catch(err => {
            console.log('err= ', err);
        })
    }

    const handleUpdateFilterTags = (tag) => {
        const tagIndex = selectedTags.findIndex(t => t._id === tag._id)
        if (tagIndex !== -1) {
            //add to remove tags
            setRemoveTags(
                removeTags.concat(tag)
            )
            setSelectedTags([...selectedTags.slice(0, tagIndex), ...selectedTags.slice(tagIndex + 1, selectedTags.length)])

        } else {
            setSelectedTags([...selectedTags, tag])

            //remove from indeterminate tags
            setIndeterminateTags(
                indeterminateTags.filter(it => `${it._id}` != `${tag._id}`)
            )

            let r = removeTags.filter(removeTag => {
                return `${removeTag._id}` != `${tag._id}`
            })

            setRemoveTags(r)
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggleOpen}
        >
            <div className="photo__modal">
                <span className="photo__modal__title">{t('Edit information')}</span>
                <div className="photo__modal__container">

                    <div id='selected-photos' style={{'marginBottom': '20px'}}>
                        <span>Selected photos ({selectedPhotos.length})</span>
                        <div className="export__modal__list flex-wrap">
                            {selectedPhotos.map((photo, i) => (
                                <div style={{width: '140px'}}
                                     className={`export__modal__item ${i > 5 && hideMoreSelectedImages ? 'd-none' : ''}`}
                                     key={photo._id}>
                                    <div
                                        title={photo.name}
                                        className="export__modal__item__header d-flex"
                                        style={{"gap": "4px", "maxHeight": "20px", "overflow": "hidden"}}>
                                        <img src={photo.imageUrl || photo.path}/>
                                        <span style={{
                                            "textOverflow": "ellipsis",
                                            "overflow": "hidden"
                                        }}>{photo.name}</span>
                                    </div>
                                </div>

                            ))}
                        </div>

                        {selectedPhotos.length > 6 && <a onClick={(e) => {
                            e.preventDefault();
                            setHideMoreSelectedImages(!hideMoreSelectedImages)
                        }} className="d-block"
                                                         href="#"> {hideMoreSelectedImages ? t('Show more') : t('Hide')} ({selectedPhotos.length - 6}) </a>}
                    </div>


                    {showDetailsStep && <>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <label>{t('Photo title')}</label>
                                    <Input
                                        name="Photo title"
                                        type="text"
                                        className={classnames('form-control form-control-lg')}
                                        value={photoTitle}
                                        onChange={(e) => setPhotoTitle(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormGroup>
                                    <label>{t('Description')}</label>
                                    <Input
                                        name="Photo description"
                                        type="textarea"
                                        className={classnames('form-control form-control-lg')}
                                        value={photoDescription}
                                        onChange={(e) => setPhotoDescription(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </>}

                    {showTagsStep && <>
                        <div className="photo__modal__tags">
                            <span>{t('Tag selection')}</span>
                            {selectedTags.length ? (
                                <div className="photo__tag__container">
                                    <span>Selected</span>
                                    <div className="photo__tag__list">
                                        {selectedTags.map(t => <div className="photo__tag--selected" key={t._id}>
                                            <span>{t.name}</span>
                                            <img className="pointer" src={closeTag}
                                                 onClick={() => handleUpdateFilterTags(t)}/>
                                        </div>)}
                                    </div>
                                </div>
                            ) : null}
                            <TagList tags={tags} selectedTags={selectedTags}
                                     indeterminateTags={indeterminateTags}
                                     handleUpdateSelectedTags={handleUpdateFilterTags}/>
                        </div>
                    </>}

                    <Row>
                        {/*<Col md={showDetailsStep ? 4 : 6}>*/}
                        {/*    <Button color="secondary" onClick={goBack} className="photo__modal__btn">{t('Back')}</Button>*/}
                        {/*</Col>*/}

                        <Col md={12}>
                            <Button color="info" onClick={saveCurrentStep} className="photo__modal__btn">{t('Save')}</Button>
                        </Col>

                        {/*{showDetailsStep && <Col md={4}>*/}
                        {/*    <Button color="info" onClick={saveAndSelectTags} className="photo__modal__btn">*/}
                        {/*        {t('Save and select tags')}*/}
                        {/*    </Button>*/}
                        {/*</Col>}*/}
                    </Row>
                </div>


            </div>
            {toastMessage && (
                <div className="photo__options__toast">
                    <img src={check}/>
                    <span>{toastMessage}</span>
                </div>
            )}
        </Modal>
    )
}
