import React from "react";
import ReactTable from "react-table";
import axios from "axios";
import moment from "moment"
import {getGeneralComments, updateGeneralComments, getGeneralCommentsByDateAndByProject} from "../../actions/index";
import InputMask from 'react-input-mask';
import {withTranslation} from 'react-i18next';

import {
    Button,
    Input,
    Row,
    Col,
} from "reactstrap";

import {connect} from "react-redux";
import {userCant} from "../../services/Authorization/authService";

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        project: state.allOtherReducers.project,
        projectId: state.allOtherReducers.projectId,
        projectData: state.allOtherReducers.projectData,
        reportDate: state.allOtherReducers.reportDate,
        // generalCommentData: state.allOtherReducers.generalCommentData,
        allGeneralCommentData: state.allOtherReducers.allGeneralCommentData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getGeneralComments: projectId => dispatch(getGeneralComments(projectId)),
        updateGeneralComments: payload => dispatch(updateGeneralComments(payload)),
        getGeneralCommentsByDateAndByProject: (projectId, date) => dispatch(getGeneralCommentsByDateAndByProject(projectId, date))
    };
}

class ConnectedAddGeneralCommentsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            date: '',
            editContent: false,
            editingRow: -1,
            isDirty: false
        }

        this.renderEditable = this.renderEditable.bind(this);
        this.saveRowData = this.saveRowData.bind(this);
        this.toggleEditable = this.toggleEditable.bind(this);
        this.renderActiveButtons = this.renderActiveButtons.bind(this);
        this.deleteGeneralCommentById = this.deleteGeneralCommentById.bind(this)
    }


    componentDidMount(props) {
    }


    componentWillReceiveProps({dateSelected}) {
        this.setState({...this.state, dateSelected}, () => this.state)
    }


    deleteGeneralCommentById(generalCommentId) {
        axios.delete(process.env.REACT_APP_API_URL + '/api/generalcomment/' + generalCommentId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
                this.props.getGeneralComments({projectId: this.props.projectId})
                this.props.getGeneralCommentsByDateAndByProject({
                    projectId: this.props.projectId,
                    reportDate: this.props.reportDate
                })
            }
        ).catch((error) => {
        })
    }

    handleInputChange = (cellInfo, event) => {
        let data = [...this.props.generalCommentData];
        data[cellInfo.index][cellInfo.column.id] = event.target.value;

        this.setState({data});
    };

    renderEditable(cellInfo) {

        const cellValue = this.props.generalCommentData[cellInfo.index][cellInfo.column.id];

        const {editingRow} = this.state;
        return (
            <div>
                {editingRow === cellInfo.index ? (
                    <Input
                        maskChar=""
                        mask=""
                        placeholder=""
                        onChange={this.handleInputChange.bind(null, cellInfo)}
                        value={cellValue}
                        tag={InputMask}
                    />
                ) : (<div>
                    {cellInfo.value}
                </div>)}
            </div>
        );
    }

    saveRowData(cellInfo) {
        this.setState({editContent: false}, () => console.log(cellInfo.original.generalComment))
        var projectId = this.props.projectId
        // this.props.getGeneralComments({projectId})
        axios.put(process.env.REACT_APP_API_URL + '/api/generalComment/', {
            _id: cellInfo.original._id,
            generalComment: cellInfo.original.generalComment,

        }, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            console.log(res)
            this.setState({
                editingRow: -1,
                isDirty: false
            })
            return res.data
        })
            .catch((error) => {
                ;
            })
    }

    toggleEditable(comment) {
        this.props.setEditingComment(comment)
    }

    renderActiveButtons(row) {
        if (userCant(this.props.user, 'generalComments.manage')) {
            return;
        }
        return (<div>
            <Button
                onClick={() => this.toggleEditable(row.original)}
                size="xs"
                className="btn-icon btn-neutral btn btn-info"
            ><i className="fa fa-edit"
            /></Button>
            <Button
                color="danger"
                size="sm"
                className="btn-icon btn-neutral btn btn-info"
                onClick={() => {
                    this.deleteGeneralCommentById(row.original._id)
                }
                }
            ><i
                className="fa fa-remove"
            />
            </Button>
        </div>)


    }


    render() {
        return (
            <>
                <div className="content">
                    <ReactTable
                        data={this.props.generalCommentData}
                        columns={[
                            {
                                Header: this.props.t("General Comments Id"),
                                accessor: "_id",
                                show: false
                            }, {
                                Header: this.props.t("General Comments"),
                                accessor: "generalComment",
                                Cell: this.renderEditable
                            },
                            {
                                Header: this.props.t("Actions"),
                                accessor: "actions",
                                sortable: false,
                                filterable: false,
                                minWidth: 100,
                                maxWidth: 200,
                                Cell: ((value) => (this.renderActiveButtons(value)))
                            },

                        ]}
                        defaultPageSize={1}
                        pageSize={this.props.generalCommentData.length}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        className="-striped -highlight primary-pagination"
                    />
                </div>
            </>
        );
    }
}

const AddGeneralCommentsTable = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConnectedAddGeneralCommentsTable));
export default AddGeneralCommentsTable;
