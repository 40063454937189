import React from "react";
import Select from "react-select";
import axios from "axios"
import Datetime from 'react-datetime';
import ReactTable from "react-table";
import {addProject, getProjects, refreshProjects, setProject} from 'actions/index'
import moment from 'moment'
import InputMask from 'react-input-mask';
var parseString = require('xml2js').parseString;
const {JSONPath} = require('jsonpath-plus');
import {Dock} from "react-dock"
import {onColumnFilter} from '../filters/ColumnFilter'
import {withTranslation} from 'react-i18next';

// reactstrap components
import {
    Button,
    FormGroup,
    Form,
    Input,
    Row,
    Col, Modal, ModalHeader, ModalBody, ModalFooter, Label, CardBody, TabContent, TabPane, Nav, NavItem, NavLink

} from "reactstrap";
import {connect} from "react-redux";
import ReactBSAlert from "react-bootstrap-sweetalert";
import classnames from "classnames";
import {userCan, userCant} from "../../services/Authorization/authService";
import RenderCustomFields from "../pages/CustomFields/Render/RenderCustomFields";

function mapDispatchToProps(dispatch) {
    return {
        addProject: project => dispatch(addProject(project)),
        getProjects: () => dispatch(getProjects()),
        refreshProjects: () => dispatch(refreshProjects()),
        setProject: project => dispatch(setProject(project)),
    };
}

function mapStateToProps(state) {
    return {
        project: state.allOtherReducers.project,
        projectId: state.allOtherReducers.projectId,
        reportDate: state.allOtherReducers.reportDate,
        user: state.auth.user,
        client: state.auth.client
    };
}


class ConnectedAddProjectForm extends React.Component {
    constructor(props) {
        super();
        this.state = {
            description: '',
            singleProjectValueSelect: '',
            startDate: "",
            data: '',
            name: '',
            configuration: {
                overTimeLimit: '',
                startOfDay: '',
                endOfDay: '',
                workingHoursPerDay: '',
                breakTime: ''
            },
            editContent: false,
            editingRow: -1,
            isDirty: false,
            isProjectScheduleModalOpen: false,
            projectScheduleFile: null,
            selectedProjectToUploadSchedule: null,
            validationErrors: {},
            alert: null,
            removeScheduleConfirmation: '',
            showProjectForm: false,
            errors: {},
            scheduleVersion: '',
            editingProject: null,
            companies: [],
            companiesData: [],
            companiesOptions: [],
            locations: [],
            companyLocations: [],
            companyLocationsForm: [],
            shiftTypeOption: [
                {
                    value: 'no_shifts',
                    label: props.t('No shifts')
                }, {
                    value: 'shift_based',
                    label: props.t('Shift based')
                },
            ],
            activeFormTab: 'details',
            projectData: props.projectData,
            openCustomFields: false
        };
        this.formSubmitSuccess = this.formSubmitSuccess.bind(this)
        this.renderEditable = this.renderEditable.bind(this);
        this.saveRowData = this.saveRowData.bind(this);
        this.toggleEditable = this.toggleEditable.bind(this);
        this.renderActiveButtons = this.renderActiveButtons.bind(this);
        this.closeProjectScheduleModal = this.closeProjectScheduleModal.bind(this)
        this.uploadProjectSchedule = this.uploadProjectSchedule.bind(this)
        this.populateDropDownCompany = this.populateDropDownCompany.bind(this)
        this.fetchCompanies = this.fetchCompanies.bind(this)
        this.handleCheckedLocations = this.handleCheckedLocations.bind(this)
        this.removeProjectSchedule = this.removeProjectSchedule.bind(this)
        this.openCustomFieldsDock = this.openCustomFieldsDock.bind(this);
        this.onCloseCustomFieldsModal = this.onCloseCustomFieldsModal.bind(this);
        this.onCustomFieldsSuccess = this.onCustomFieldsSuccess.bind(this);

    }

    onCloseCustomFieldsModal(record) {
        this.setState({
            openCustomFields: false,
            editingProject: null
        }, () => {
            if (record) {
                this.onCustomFieldsSuccess()
            }
        })
    }

    onCustomFieldsSuccess() {
        this.props.refreshProjects()

    }

    handleCheckedLocations(e, location, company) {
        if (e.target.checked) {
            this.state.locations.push({
                location,
                company: company
            });
        } else {
            this.state.locations = this.state.locations.filter(r => r.location._id !== location._id);
        }

        this.setState({
            locations: Array.from(new Set(this.state.locations))
        })
    }

    componentDidMount() {
        this.fetchCompanies();
    }

    openCustomFieldsDock(row) {
        this.setState({
            openCustomFields: !this.state.openCustomFields,
            editingProject: row.original
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.projectId !== prevProps.projectId) {
            this.fetchCompanies();
        }

        if (prevProps.projectData !== this.props.projectData) {
            this.setState({
                projectData: this.props.projectData
            })
        }
    }

    handleTagsinput = tagsinput => {
        this.setState({tagsinput});

    };

    fetchCompanies() {
        axios.get(process.env.REACT_APP_API_URL + "/api/company?projectId=" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.setState({
                companiesData: res.data
            }, () => {
                this.populateDropDownCompany(
                    this.state.companiesData
                )
            });
            return res.data
        })
    }

    handleOnChange(value) {
        alert(value)
    }

    populateDropDownCompany(data) {
        const options = data.map(company => {
            return {value: company._id, label: company.name}
        })

        this.setState({companiesOptions: options})
    }

    formSubmitSuccess(e) {
        e.preventDefault();

        this.setState({
            errors: {}
        })


        if (this.state.editingProject) {
            return this.saveRowData()
        }

        const {name, description, startDate, customerId, shiftType, configuration} = this.state;

        let companies = [],
            locations = [];

        this.state.companyLocationsForm.forEach(row => {
            companies.push(row.company._id)
            locations.push(row.location._id)
        })

        this.props.addProject({
            name,
            description,
            startDate,
            customerId,
            companies: Array.from(new Set(companies)),
            locations: locations,
            shiftType: shiftType?.value,
            configuration
        }).then(res => {
            this.clearForm();
        }).catch((error) => {
            this.setState({
                errors: error.response.data
            })
        })
    }

    clearForm() {
        this.setState({
            description: '',
            name: '',
            startDate: '',
            errors: {},
            scheduleVersion: '',
            scheduleVersions: [],
            editingProject: null,
            showProjectForm: false,
            companies: [],
            configuration: {
                overTimeLimit: '',
                startOfDay: '',
                endOfDay: '',
                workingHoursPerDay: '',
                breakTime: ''
            },
            companyLocations: [],
            companyLocationsForm: [],
            locations: [],
            shiftType: {},
            showAssignCompaniesForm: false
        }, () => this.state.description)
    }


    // componentWillReceiveProps(prevProps, { projectData }) {
    //   this.setState({
    //     ...this.state, projectData
    //   }, () => console.log(this.props))

    // }

    handleInputChange = (value) => {
        this.setState({
            scheduleVersion: value
        })
    };

    renderEditable(cellInfo) {
        return <div> {cellInfo.value} </div>
    }

    renderCompanies(project) {
        if (!project.companies) {
            return ''
        }

        let companies = [];

        project.companies.forEach(company => {
            company.locations = project.locations.filter(l => l.companyId == company._id)
            companies.push(company);
        })


        return companies.map((company, index) => <label
            key={`co_${index}`}
            className="p-1 mr-1 rounded bg-success text-white">
            {company.name} <span
            style={{'fontSize': '10px'}}>{company.locations.length ? `( ${company.locations.map(l => l.name).join(', ')}   )` : ''}</span>
        </label>)
    }

    availableLocations(companiesIds) {
        let locations = [];

        companiesIds.forEach(companyId => {
            let foundCompany = this.state.companiesData.find(c => c._id == companyId);
            if (foundCompany && foundCompany.locations) {

                const nonAddedLocations = foundCompany.locations.filter(l => {
                    return !this.state.companyLocationsForm.find(row => row.location._id == l._id)
                })

                locations.push({
                    company: foundCompany,
                    locations: nonAddedLocations
                })
            }
        })

        return locations;
    }

    renderScheduleVersionEditableField() {

        if (!this.state.editingProject) {
            return
        }

        let scheduleVersions = this.state.editingProject.scheduleVersions || [];

        if (!scheduleVersions.length) {
            return '';
        }


        return <div style={{'maxHeight': '200px', 'overflowY': 'scroll', 'fontSize': '16px'}}>
            <label>Schedule Version</label>
            {scheduleVersions.map((option, index) => <div key={`v_${index}`}><Input
                key={`op_${index}`}
                checked={this.state.scheduleVersion === option.label}
                id={`${option.label}_${index}`}
                addon type="radio"
                onChange={(e) => this.handleInputChange(option.label)}
            />
                <Label check for={`${option.label}_${index}`}
                       className="ml-1">
                    {option.label}
                </Label>


                {option.canBeRemoved && this.state.scheduleVersion !== option.label &&
                <a onClick={() => this.removeProjectScheduleConfirmation(option.label)}
                   href={'#'} className={'ml-3'}> <i className={'fa text-danger fa-trash'}></i></a>}
            </div>)}

            {this.state.errors.scheduleVersion && (
                <div className="invalid-feedback">{this.state.errors.scheduleVersion}</div>
            )}
        </div>
    }

    /**
     *
     * @param version
     */
    removeProjectScheduleConfirmation(version) {
        this.setState({
            removeScheduleConfirmation: <ReactBSAlert
                warning
                style={{display: "block", marginTop: "-100px", 'zIndex': '999999999'}}
                title={`${this.props.t("Delete Project Schedule ")} ${version} ?`}
                onConfirm={() => this.removeProjectSchedule(version)}
                onCancel={() => this.setState({removeScheduleConfirmation: null})}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                confirmBtnText={`${this.props.t('Yes')}, ${this.props.t('delete it')}!`}
                cancelBtnText="Cancel"
                showCancel
            >
                {this.props.t('You will not be able to recover this')}!
            </ReactBSAlert>
        });

    }

    /**
     *
     * @param version
     */
    removeProjectSchedule(version) {
        this.setState({
            removeScheduleConfirmation: <ReactBSAlert
                style={{display: "block", marginTop: "-100px", 'zIndex': '999999999'}}
                title={this.props.t("Deleting Project schedule ") + version}
                onConfirm={() => this.setState({removeScheduleConfirmation: null})}
                showConfirm={false}
            >
                {this.props.t('Deleting project schedule is in progress')}...
            </ReactBSAlert>
        })

        axios.delete(`/api/projectschedule/delete-project-schedule/project/${this.state.editingProject._id}?version=${version}&selectedVersion=${this.state.scheduleVersion}`, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(success => {

            this.state.editingProject.scheduleVersions = this.state.editingProject.scheduleVersions
                .filter(s => s.label !== version);

            this.setState({
                editingProject: this.state.editingProject
            }, () => {
                this.setState({
                    removeScheduleConfirmation: null
                })
            });

            this.props.refreshProjects();
        }).catch(err => {
        }).finally(() => {
            this.setState({
                removeScheduleConfirmation: null
            })
        })
    }

    saveRowData() {
        let companies = [],
            locations = [];

        this.state.companyLocationsForm.forEach(row => {
            companies.push(row.company._id)
            locations.push(row.location._id)
        })

        axios.put(process.env.REACT_APP_API_URL + '/api/project/', {
            _id: this.state.editingProject._id,
            name: this.state.name,
            description: this.state.description,
            scheduleVersion: this.state.scheduleVersion,
            startDate: this.state.startDate,
            scheduleVersions: this.state.scheduleVersions.map(s => s.label),
            companies: Array.from(new Set(companies)),
            configuration: {
                overTimeLimit: this.state.configuration.overTimeLimit,
                startOfDay: this.state.configuration.startOfDay,
                endOfDay: this.state.configuration.endOfDay,
                workingHoursPerDay: this.state.configuration.workingHoursPerDay,
                breakTime: this.state.configuration.breakTime,
            },
            locations: locations,
            shiftType: this.state.shiftType?.value
        }, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.props.refreshProjects()
            this.clearForm();
            this.setState({
                editingProject: null,
                showProjectForm: false
            })
            return res.data
        })
            .catch((error) => {
                this.setState({
                    errors: error.response.data
                })
            })
    }

    toggleEditable(project) {
        let companyLocationsForm = [];

        if (project.companies) {
            project.companies.forEach(company => {
                company.locations.forEach(l => {
                    const foundLocation = project.locations.find(pL => pL._id == l._id);
                    if (!foundLocation) {
                        return;
                    }
                    companyLocationsForm.push({
                        company: company,
                        location: foundLocation
                    })
                })
            })
        }

        let shiftTypeOption;

        if (project.shiftType) {
            shiftTypeOption = this.state.shiftTypeOption.find(s => s.value == project.shiftType);
        }
        this.setState({
            editingProject: project,
            showProjectForm: true,
            showAssignCompaniesForm: false,
            name: project.name,
            description: project.description,
            startDate: moment(project.startDate).format('DD MMM YYYY'),
            scheduleVersions: project.scheduleVersions,
            scheduleVersion: project.scheduleVersion,
            companies: [],
            configuration: {
                overTimeLimit: project.configuration?.overTimeLimit,
                startOfDay: project.configuration?.startOfDay,
                endOfDay: project.configuration?.endOfDay,
                workingHoursPerDay: project.configuration?.workingHoursPerDay,
                breakTime: project.configuration?.breakTime,
            },
            companyLocations: [],
            companyLocationsForm: companyLocationsForm,
            locations: [],
            shiftType: shiftTypeOption
        });
    }


    renderActiveButtons(row) {
        return (<div>
            <Button
                onClick={() => this.toggleEditable(row.original)}
                size="xs"
                className="btn-icon btn-neutral btn btn-info"
            ><i className="fa fa-edit"
            /></Button>
            {userCan(this.props.user, 'projects.upload_project_schedule') && <Button
                onClick={() => {
                    this.setState({selectedProjectToUploadSchedule: row.original, isProjectScheduleModalOpen: true})
                }}
                size="xs"
                title={this.props.t("Upload project schedule")}
                className="btn-icon btn-neutral btn btn-info"
            ><i className="fa fa-upload"
            /></Button>
            }
            {this.props.projectId !== row.original._id
            &&
            <Button
                onClick={() => {
                    this.props.setProject({
                        label: row.original.name,
                        value: row.original._id,
                    })
                }}
                size="xs"
                title={this.props.t("Set as current project")}
                className="btn-icon btn-neutral btn btn-info"
            ><i className="fa fa-circle-o"
            /></Button>
            }
            {this.props.projectId === row.original._id
            &&
            <Button
                size="xs"
                title={this.props.t("Current active project")}
                className="btn-icon btn-neutral btn btn-success"
            ><i className="fa fa-dot-circle-o"
            /></Button>
            }

            {this.props.client?.config?.enableCustomFields && <Button
                onClick={() => this.openCustomFieldsDock(row)}
                size="xs"
                className="btn-icon btn-neutral btn btn-info mt-2"
            ><i className="fa fa-exclamation"
            /></Button>}

        </div>)

    }

    showHideLoaderMessage = (hide = false) => {
        this.setState({
            alert: hide ? null : (
                <ReactBSAlert
                    style={{display: "block", marginTop: "-100px"}}
                    title={this.props.t("Project schedule uploading")}
                    onConfirm={() => this.showHideLoaderMessage(true)}
                    showConfirm={false}
                >
                    {this.props.t('Uploading project schedule is in progress')}...
                </ReactBSAlert>
            )
        });
    }

    closeProjectScheduleModal() {
        this.setState({
            isProjectScheduleModalOpen: false,
            projectScheduleFile: null,
            selectedProjectToUploadSchedule: null,
            validationErrors: {}
        })
    }

    uploadProjectSchedule() {

        if (userCant(this.props.user, 'projects.upload_project_schedule')) {
            return;
        }

        const file = this.state.projectScheduleFile;

        this.setState({
            validationErrors: {}
        })

        if (!file) {
            this.setState({
                validationErrors: {
                    schedule_file: this.props.t('XML File is required')
                }
            })
            return;
        }

        if (file.type !== 'text/xml') {
            this.setState({
                validationErrors: {
                    schedule_file: this.props.t('Only XML files are accepted')
                }
            })

            return;
        }
        const reader = new FileReader();

        var projectId = this.state.selectedProjectToUploadSchedule._id,
            projectScheduleVersions = this.state.selectedProjectToUploadSchedule.scheduleVersions || []

        let projectScheduleVersion = projectScheduleVersions.map(v => Number(v.label.replace(/^\D+/g, ''))).sort().reverse()[0];

        reader.addEventListener('load', e => {
            this.showHideLoaderMessage();
            let xmlBody = e.target.result;

            parseString(xmlBody, (err, result) => {
                var names = JSONPath({path: '$..Task[*][Name,WBS,OutlineLevel]', json: result});
                var namesx = JSONPath({path: '$..Task[*]', json: result, resultType: 'all'});
                let setID3 = (item, index) => {
                    var nameProp = 'Name';


                    if (Object.hasOwnProperty.bind(item.value)(nameProp)) {
                        var nameFound = item.value.Name
                    }

                    var wbsProp = 'OutlineNumber';
                    if (Object.hasOwnProperty.bind(item.value)(wbsProp)) {
                        var wbsFound = item.value.OutlineNumber
                    }

                    var outlineLevelProp = 'OutlineLevel';

                    if (Object.hasOwnProperty.bind(item.value)(outlineLevelProp)) {
                        var outlineLevelFound = item.value.OutlineLevel
                    }

                    var bashy = {}
                    var levelInt = parseInt(outlineLevelFound, 10)

                    bashy.text = Array.isArray(nameFound) ? nameFound[0] : 'NO VALUE';

                    bashy.outline = wbsFound[0];
                    bashy.level = levelInt;

                    return bashy;
                };

                var output = []
                output = namesx.map(setID3);
                output.shift();
                var results = [];
                while (names.length) {
                    results.push(names.splice(0, 3));
                }


                let myParentIs = (outline, text) => {

                    //1.1
                    // myParentIs 1
                    // 1.1.1.1
                    // myParentIs
                    var lengthOfLastItem

                    if (outline.match(/\./g || []) != null) {
                        var splitOutline = outline.split('.')
                        var lengthSplitOutline = splitOutline.length
                        lengthOfLastItem = splitOutline[lengthSplitOutline - 1].length
                    } else {
                        lengthOfLastItem = 0
                    }
                    var parentId = outline.length - lengthOfLastItem
                    var parentOutine
                    if (typeof outline === 'string') {
                        parentOutine = outline.substring(0, parentId - 1)
                    }

                    return parentOutine

                }

                let getLevel = (outline) => {
                    var outlineSize = outline.split('.')
                    return outlineSize.length
                }

                var gesult = []
                var levels = [gesult];

                if (projectScheduleVersion) {
                    projectScheduleVersion = (projectScheduleVersion + 1).toFixed(1)
                } else {
                    projectScheduleVersion = Number(1.0).toFixed(1)
                }

                for (let {text, outline, level} of output) {
                    var parentLocator = myParentIs(outline, text)
                    var levelIndicator = getLevel(outline)

                    levels[level - 1].push({
                        scheduleVersion: `v${projectScheduleVersion}`,
                        projectId: projectId,
                        title: text,
                        outline: outline,
                        order: outline.split('.').pop() - 1,
                        childNodes: levels[level] = [],
                        parent: parentLocator,
                        level: levelIndicator
                    })
                }

                let flat = (array) => {
                    var result = [];
                    array.forEach(function (a) {
                        result.push(a);
                        if (Array.isArray(a.childNodes)) {
                            result = result.concat(flat(a.childNodes));
                        }
                    });
                    return result;
                }

                var flattenedResults = flat(gesult)

                axios.post(`/api/projectschedule/store-project-schedule-items/project/${projectId}`, {
                    scheduleItems: flattenedResults
                }, {
                    headers: {
                        'Authorization': localStorage.jwtToken
                    }
                }).then(success => {
                    this.props.refreshProjects()
                    this.closeProjectScheduleModal()
                }).catch(err => {
                }).finally(() => {
                    this.showHideLoaderMessage(true)
                })
            });

        })
        reader.readAsText(this.state.projectScheduleFile);
    }

    toggleActiveTabForm(tabId) {
        if (this.state.activeFormTab !== tabId) {
            this.setState({
                activeFormTab: tabId
            });
        }
    }

    assignLocations() {
        let companyLocationsForm = this.state.companyLocationsForm;

        companyLocationsForm.unshift(...this.state.locations);

        let companiesIds = new Set(this.state.companies.map(item => item.value));

        this.setState({
            companyLocationsForm: companyLocationsForm,
            companyLocations: this.availableLocations(companiesIds),
            locations: []
        })
    }

    removeLocation(row) {
        let canBeUnassigned = row.location.can_be_unassigned;

        if (canBeUnassigned === undefined) {
            canBeUnassigned = true;
        }

        if (!canBeUnassigned) {
            return
        }

        let companyLocationsForm = this.state.companyLocationsForm;

        companyLocationsForm = companyLocationsForm.filter(item => {
            return !(item.company._id == row.company._id && item.location._id == row.location._id)
        })


        this.setState({
            companyLocationsForm: companyLocationsForm,
            locations: []
        }, () => {
            let companiesIds = new Set(this.state.companies.map(item => item.value));
            this.setState({
                companyLocations: this.availableLocations(companiesIds)
            })
        })
    }

    render() {
        const {name, description, startDate, customerId, errors} = this.state;
        return (
            <>
                {this.state.alert}
                {this.state.removeScheduleConfirmation}
                <Button color="info" type="submit"
                        onClick={() => this.setState({showProjectForm: !this.state.showProjectForm})}>
                    {this.props.t('Add Project')}
                </Button>
                {/* onSubmit={this.formSubmitSuccess} */}
                <Dock
                    position='right'
                    isVisible={this.state.showProjectForm}
                    duration={300}
                    size={window.innerWidth < 600 ? 1 : (window.innerWidth < 900 ? 0.5 : 0.3)}
                >

                    {this.state.showProjectForm &&
                    <CardBody>
                        <Button color="info" className="" onClick={() => {
                            this.clearForm()
                        }}>
                            {this.props.t('Cancel')}
                        </Button>
                        <Form className="form-horizontal" onSubmit={(e) => this.formSubmitSuccess(e)}>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: this.state.activeFormTab === 'details',
                                            'text-danger': Object.keys(errors).filter(k => !['locations', 'companies'].includes(k)).length
                                        })}
                                        onClick={() => {
                                            this.toggleActiveTabForm('details')
                                        }}
                                    >
                                        {this.props.t('Details')}
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                                active: this.state.activeFormTab === 'companies',
                                                'text-danger': errors.companies || errors.locations
                                            }
                                        )}
                                        onClick={() => {
                                            this.toggleActiveTabForm('companies')
                                        }}
                                    >
                                        {this.props.t('Companies')}
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                                active: this.state.activeFormTab === 'configuration',
                                                'text-danger': errors.configuration
                                            }
                                        )}
                                        onClick={() => {
                                            this.toggleActiveTabForm('configuration')
                                        }}
                                    >
                                        {this.props.t('Configuration')}
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={this.state.activeFormTab}>
                                <TabPane tabId="details">
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <label>{this.props.t('Project Name')}</label>
                                                <Input
                                                    name="name"
                                                    type="text"
                                                    className={classnames('form-control form-control-lg', {
                                                        'is-invalid': errors.name
                                                    })}
                                                    onChange={(e) => this.setState({name: `${e.target.value}`})
                                                    }
                                                    value={this.state.name}

                                                />
                                                {errors.name && (
                                                    <div className="invalid-feedback">{errors.name}</div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <label>{this.props.t('Project Description')}</label>
                                                <Input
                                                    name="description"
                                                    type="text"
                                                    className={classnames('form-control form-control-lg', {
                                                        'is-invalid': errors.description
                                                    })}
                                                    value={this.state.description}
                                                    onChange={(e) => this.setState({description: `${e.target.value}`})}
                                                />
                                                {errors.description && (
                                                    <div className="invalid-feedback">{errors.description}</div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <label>{this.props.t('Start Date')}</label>
                                                <Datetime
                                                    onChange={value =>
                                                        this.setState({startDate: value})
                                                    }
                                                    closeOnSelect={true}
                                                    timeFormat={false}
                                                    utc={true}
                                                    className={classnames('form-control form-control-lg', {
                                                        'is-invalid': errors.startDate
                                                    })}
                                                    dateFormat={'DD MMM YYYY'}
                                                    inputProps={{placeholder: this.props.t("Select Date")}}
                                                    value={this.state.startDate}
                                                />
                                                {errors.startDate && (
                                                    <div className="invalid-feedback">{errors.startDate}</div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>

                                            <label>{this.props.t('Shift type')}</label>
                                            <Select options={this.state.shiftTypeOption}
                                                    defaultValue={this.state.shiftType}
                                                    className={classnames('form-control', {
                                                        'is-invalid': this.state.errors.shiftType
                                                    })}
                                                    onChange={e => {
                                                        this.setState({shiftType: e})
                                                    }}/>

                                            {this.state.errors.shiftType && (
                                                <div className="invalid-feedback">{this.state.errors.shiftType}</div>
                                            )}
                                            <small style={{color: 'red'}}>
                                                * {this.props.t('Any change on shift type all open project shifts will be closed')}!
                                            </small>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                {this.renderScheduleVersionEditableField()}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </TabPane>

                                <TabPane tabId="companies">

                                    <Row>
                                        <Col>
                                            <a href={'#'} className={classnames('btn btn-success btn-sm')}
                                               onClick={(e) => {
                                                   e.preventDefault();
                                                   this.setState({showAssignCompaniesForm: !this.state.showAssignCompaniesForm})
                                               }}>

                                                {!this.state.showAssignCompaniesForm &&
                                                <i className={'fa fa-plus'}></i>}
                                                {this.state.showAssignCompaniesForm &&
                                                <i className={'fa fa-minus'}></i>}
                                                {this.props.t('Assign Company')}
                                            </a>
                                        </Col>
                                    </Row>

                                    {this.state.showAssignCompaniesForm && <>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{this.props.t('Companies')}</Label>
                                                    <Select
                                                        name="companies"
                                                        value={this.state.companies}
                                                        isMulti
                                                        onChange={companyOption => {
                                                            this.setState({
                                                                companies: companyOption || [],
                                                            }, () => {
                                                                let availableLocations = this.availableLocations(this.state.companies.map(c => c.value));

                                                                this.setState({
                                                                    companyLocations: availableLocations
                                                                })
                                                            })
                                                        }}
                                                        className={classnames('form-control react-select primary', {
                                                            'is-invalid': errors.companies
                                                        })}
                                                        options={this.state.companiesOptions}
                                                        placeholder={this.props.t('Companies')}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {this.state.companyLocations.length > 0 && <Row style={{
                                            maxHeight: "200px",
                                            overflowY: "scroll",
                                            overflowX: 'scroll',
                                        }}>
                                            <Col>
                                                <label className="m-0">{this.props.t('Locations')}</label>
                                                <br/>
                                                {this.state.companyLocations.map((group, index) =>
                                                    <>
                                                        <h6 key={`h6_${index}`}
                                                            style={{
                                                                'margin': '0',
                                                                'fontWeight': '500'
                                                            }}>{group.company.name}</h6>

                                                        <div key={`co_div_${index}`} className={'ml-3 mb-1'}>
                                                            {!group.locations.length &&
                                                            <label>{this.props.t('All location already added')}</label>}
                                                            {group.locations.map((c, index) => <Row key={`c_${index}`}>
                                                                <Col md={12}>
                                                                    <FormGroup className="d-inline mr-2"
                                                                               style={{"fontSize": "16px"}}
                                                                    >
                                                                        <Input
                                                                            checked={!!this.state.locations.find(_item => _item.location._id == c._id)}
                                                                            id={c._id}
                                                                            addon type="checkbox"
                                                                            onChange={(e) => this.handleCheckedLocations(e, c, group.company)}
                                                                        />
                                                                        <Label style={{
                                                                            "whiteSpace": "nowrap",
                                                                            'position': 'absolute'
                                                                        }}
                                                                               for={c._id} className="ml-1">
                                                                            {c.name} ({c.address})
                                                                        </Label>
                                                                    </FormGroup>

                                                                </Col>
                                                            </Row>)}
                                                        </div>
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                        }


                                        <Row>
                                            <Col>
                                                <a href={'#'} className={classnames('btn btn-success btn-sm', {
                                                    disabled: !this.state.locations.length
                                                })} onClick={() => {
                                                    this.assignLocations()
                                                }}>
                                                    <i className={'fa fa-plus'}></i> {this.props.t('Assign')}
                                                </a>
                                            </Col>
                                        </Row>

                                        <hr/>
                                    </>
                                    }


                                    <Row style={{'padding': '10px', 'fontSize': '12px'}}>
                                        <table className={'table table-stripe'}>
                                            <thead>
                                            <tr>
                                                <th style={{'fontSize': '12px'}}>{this.props.t('Company')}</th>
                                                <th style={{'fontSize': '12px'}}>{this.props.t('Location name')}</th>
                                                <th style={{'fontSize': '12px'}}>{this.props.t('Location address')}</th>
                                                <th style={{'fontSize': '12px'}}>{this.props.t('Actions')}</th>
                                            </tr>
                                            </thead>

                                            <tbody>


                                            {
                                                !this.state.companyLocationsForm.length &&
                                                <tr>
                                                    <td colSpan={4} className={'text-center'}>
                                                        {this.props.t('No Companies assigned yet')}!
                                                        {!this.state.showAssignCompaniesForm && <>
                                                            <br/>
                                                            <a href={'#'}
                                                               className={classnames('btn btn-success btn-sm')}
                                                               onClick={() => {
                                                                   this.setState({showAssignCompaniesForm: !this.state.showAssignCompaniesForm})
                                                               }}>{this.props.t('Click here to assign')} </a>
                                                        </>
                                                        }
                                                    </td>
                                                </tr>
                                            }

                                            {this.state.companyLocationsForm.map((row, companyLocationsFormIndex) =>
                                                <tr key={`companyLocationsForm_${companyLocationsFormIndex}`}>
                                                    <td>{row.company.name}</td>
                                                    <td>{row.location.name}</td>
                                                    <td>{row.location.address}</td>
                                                    <td>
                                                        {(row.location.can_be_unassigned === undefined ? true : row.location.can_be_unassigned) &&
                                                        <a href={'#'} onClick={() => this.removeLocation(row)}
                                                           className={'btn btn-danger btn-sm'}>
                                                            <i className={'fa fa-trash'}></i>
                                                        </a>
                                                        }
                                                    </td>
                                                </tr>
                                            )}

                                            </tbody>
                                        </table>
                                    </Row>

                                    {errors.locations && (
                                        <div className="invalid-feedback d-block">{errors.locations}</div>
                                    )}

                                    {errors.companies && (
                                        <div
                                            className="invalid-feedback d-block">{errors.companies}</div>
                                    )}
                                </TabPane>

                                <TabPane tabId="configuration">
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup className="d-inline mr-2">
                                                <Label className="ml-1">{this.props.t('Start Of Day')}</Label>
                                                <Input
                                                    value={this.state.configuration.startOfDay}
                                                    placeholder={this.props.t('Start Of Day')}
                                                    type="time"
                                                    className={classnames({
                                                        'is-invalid': errors.configuration?.startOfDay
                                                    })}
                                                    name="startOfDay"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            configuration: {
                                                                ...this.state.configuration,
                                                                [e.target.name]: e.target.value
                                                            }
                                                        })
                                                    }}
                                                />

                                                {errors.configuration?.startOfDay && (
                                                    <div
                                                        className="invalid-feedback">{errors.configuration?.startOfDay}</div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup className="d-inline mr-2">
                                                <Label className="ml-1">{this.props.t('End Of Day')}</Label>
                                                <Input
                                                    value={this.state.configuration.endOfDay}
                                                    placeholder={this.props.t('End Of Day')}
                                                    type="time"
                                                    className={classnames({
                                                        'is-invalid': errors.configuration?.endOfDay
                                                    })}
                                                    name="endOfDay"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            configuration: {
                                                                ...this.state.configuration,
                                                                [e.target.name]: e.target.value
                                                            }
                                                        })
                                                    }}
                                                />

                                                {errors.configuration?.endOfDay && (
                                                    <div
                                                        className="invalid-feedback">{errors.configuration?.endOfDay}</div>
                                                )}
                                            </FormGroup>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup className="d-inline mr-2">
                                                <Label
                                                    className="ml-1">{this.props.t('Break time (Mins.)')}</Label>
                                                <Input
                                                    value={this.state.configuration.breakTime}
                                                    placeholder={this.props.t('Break time (Mins.)')}
                                                    type="number"
                                                    className={classnames({
                                                        'is-invalid': errors.configuration?.breakTime
                                                    })}
                                                    name="breakTime"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            configuration: {
                                                                ...this.state.configuration,
                                                                [e.target.name]: e.target.value
                                                            }
                                                        })
                                                    }}
                                                />

                                                {errors.configuration?.breakTime && (
                                                    <div
                                                        className="invalid-feedback">{errors.configuration?.breakTime}</div>
                                                )}
                                            </FormGroup>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup className="d-inline mr-2">
                                                <Label
                                                    className="ml-1">{this.props.t('Working Hours Per Day')}</Label>
                                                <Input
                                                    value={this.state.configuration.workingHoursPerDay}
                                                    placeholder={this.props.t('Working Hours Per Day')}
                                                    type="number"
                                                    className={classnames({
                                                        'is-invalid': errors.configuration?.workingHoursPerDay
                                                    })}
                                                    name="workingHoursPerDay"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            configuration: {
                                                                ...this.state.configuration,
                                                                [e.target.name]: e.target.value
                                                            }
                                                        })
                                                    }}
                                                />

                                                {errors.configuration?.workingHoursPerDay && (
                                                    <div
                                                        className="invalid-feedback">{errors.configuration?.workingHoursPerDay}</div>
                                                )}
                                            </FormGroup>

                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12}>
                                            <FormGroup className="d-inline mr-2">
                                                <Label className="ml-1">{this.props.t('Over Time Limit')}</Label>
                                                <Input
                                                    value={this.state.configuration.overTimeLimit}
                                                    placeholder={this.props.t('Over Time Limit')}
                                                    type="number"
                                                    className={classnames({
                                                        'is-invalid': errors.configuration?.overTimeLimit
                                                    })}
                                                    name="overTimeLimit"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            configuration: {
                                                                ...this.state.configuration,
                                                                [e.target.name]: e.target.value
                                                            }
                                                        })
                                                    }}
                                                />

                                                {errors.configuration?.overTimeLimit && (
                                                    <div
                                                        className="invalid-feedback">{errors.configuration.overTimeLimit}</div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </TabPane>

                            </TabContent>

                            <Row>
                                <Col md={12}>
                                    <Button color="info" type="submit" className="w-100">
                                        {this.props.t('Save')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                    }
                </Dock>

                <Modal isOpen={this.state.isProjectScheduleModalOpen}
                       toggle={this.closeProjectScheduleModal}
                       className="modal-lg">
                    <ModalHeader toggle={this.closeProjectScheduleModal}>
                        {this.props.t('Upload new version of')}
                        <b> {this.state.selectedProjectToUploadSchedule?.name} </b>
                        <br/>
                        {this.props.t('project Schedule')}
                    </ModalHeader>
                    <ModalBody>
                        <>
                            <Label for="schedule-file-input">Project Schedule File <b>.XML</b></Label>
                            <Input id="schedule-file-input" type="file"
                                   onChange={(e) => this.setState({projectScheduleFile: e.target.files[0]})}
                                   accept="text/xml"/>
                            {this.state.validationErrors.schedule_file &&
                            <div className="text-danger">{this.state.validationErrors.schedule_file}</div>}
                        </>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary"
                                onClick={this.uploadProjectSchedule}>{this.props.t('Upload')}</Button>{' '}
                        <Button color="secondary"
                                onClick={this.closeProjectScheduleModal}>{this.props.t('Cancel')}</Button>
                    </ModalFooter>
                </Modal>


                <ReactTable
                    data={this.state.projectData}
                    columns={[
                        {
                            Header: this.props.t("Project Id"),
                            accessor: "_id",
                            show: false,
                            filterable: false
                        },
                        {
                            Header: this.props.t("Name"),
                            accessor: "name",
                            Cell: this.renderEditable
                        },
                        {
                            Header: this.props.t("Description"),
                            accessor: "description",
                            Cell: this.renderEditable
                        },
                        {
                            Header: this.props.t("Shift Type"),
                            accessor: "shiftType",
                            Cell: row => {
                                let s = row.original.shiftType?.replaceAll('_', ' ');
                                return s ? s.charAt(0).toUpperCase() + s.slice(1) : s
                            }
                        },
                        {
                            Header: this.props.t("Schedule Version"),
                            accessor: "scheduleVersion",
                            Cell: this.renderEditable
                        },
                        {
                            Header: this.props.t("Companies"),
                            accessor: "companies",
                            filterBy: "name",
                            Cell: row => this.renderCompanies(row.original),
                            sortable: false,
                        },
                        {
                            Header: this.props.t("Start Date"),
                            accessor: "startDate",
                            Cell: (props) => {
                                //props.value will contain your date
                                //you can convert your date here
                                const custom_date = moment.utc(props.value).format('DD MMM YYYY')
                                return <span>{custom_date}</span>
                            },
                        },
                        {
                            Header: this.props.t("Actions"),
                            id: "activateToggle",
                            width: 150,
                            Cell: ((value) => (this.renderActiveButtons(value))),
                            filterable: false
                        }

                    ]}
                    defaultPageSize={1}
                    pageSize={this.state.projectData.length}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    // getTrProps={this.onRowClick}
                    // getTrProps={onRowClick}
                    /*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */
                    className="-striped -highlight primary-pagination"
                    filterable
                    defaultFilterMethod={(filter, row, column) => {
                        return onColumnFilter(filter, row, column);
                    }}
                />

                <Dock
                    position='right'
                    size={window.innerWidth < 600 ? 1 : (window.innerWidth < 900 ? 0.5 : 0.35)}
                    isVisible={this.state.openCustomFields}
                    duration={300}
                >

                    {this.state.openCustomFields && this.props.client?.config?.enableCustomFields &&
                    <RenderCustomFields onClose={this.onCloseCustomFieldsModal} forModel={'project'}
                                        model={this.state.editingProject}/>}
                </Dock>
            </>
        );
    }

}

const AddProjectForm = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConnectedAddProjectForm));
export default AddProjectForm;
