import axios from 'axios';

import { GET_ERRORS } from './types';

export const addRental = (userData) => dispatch => {

  dispatch({
    type: GET_ERRORS,
    payload: ''
  })
  axios.post(process.env.REACT_APP_API_URL + "/api/rental/", {
    companyRenting: userData.companyRenting,
    rentalEquipmentId: userData.rentalEquipmentId,
    description: userData.description,
    typeModel: userData.typeModel,
    rentalCompany: userData.rentalCompany,
    rentalDate: userData.rentalDate,
    rentalFinishedDate: userData.rentalFinishedDate,
    numberOfDays: userData.numberOfDays,
    comment: userData.comment,
    projectId: userData.projectId
  }, {
    headers: {
      'Authorization': localStorage.jwtToken
    }
  }).catch(err =>
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    })
  )
}