import React from "react";
import ReactTable from "react-table";
import axios from "axios";
import moment from "moment"
import {getGeneralComments, updateGeneralComments, getGeneralCommentsByDateAndByProject} from "../../actions/index";
import InputMask from 'react-input-mask';

import {
    Button,
    Input,
    Row,
    Col,
} from "reactstrap";
import {withTranslation} from 'react-i18next';

import {connect} from "react-redux";

function mapStateToProps(state) {
    return {
        project: state.allOtherReducers.project,
        projectId: state.allOtherReducers.projectId,
        projectData: state.allOtherReducers.projectData,
        reportDate: state.allOtherReducers.reportDate,
        generalCommentData: state.allOtherReducers.generalCommentData,
        allGeneralCommentData: state.allOtherReducers.allGeneralCommentData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getGeneralComments: projectId => dispatch(getGeneralComments(projectId)),
        updateGeneralComments: payload => dispatch(updateGeneralComments(payload)),
        getGeneralCommentsByDateAndByProject: (projectId, date) => dispatch(getGeneralCommentsByDateAndByProject(projectId, date))
    };
}

class ConnectedAddGeneralCommentsPreviousTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            date: '',
            editContent: false,
            editingRow: -1,
            isDirty: false
        }

        this.renderEditable = this.renderEditable.bind(this);
        this.saveRowData = this.saveRowData.bind(this);
        this.toggleEditable = this.toggleEditable.bind(this);
        this.renderActiveButtons = this.renderActiveButtons.bind(this);
        this.deleteGeneralCommentById = this.deleteGeneralCommentById.bind(this)
    }


    componentDidMount(props) {
        console.log(this.props.allGeneralCommentData)
    }


    componentWillReceiveProps({dateSelected}) {
        this.setState({...this.state, dateSelected}, () => this.state)
    }


    deleteGeneralCommentById(generalCommentId) {
        axios.delete(process.env.REACT_APP_API_URL + '/api/generalcomment/' + generalCommentId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
                var projectId = this.props.projectId
                this.props.getGeneralComments({projectId})
            }
        )
            .catch((error) => {
                ;
            })
    }

    handleInputChange = (cellInfo, event) => {
        let data = [...this.props.generalCommentData];
        data[cellInfo.index][cellInfo.column.id] = event.target.value;

        this.setState({data});
    };

    renderEditable(cellInfo) {

        const cellValue = this.props.generalCommentData[cellInfo.index][cellInfo.column.id];

        const {editingRow} = this.state;
        return (
            <div>
                {editingRow === cellInfo.index ? (
                    <Input
                        maskChar=""
                        mask=""
                        placeholder=""
                        onChange={this.handleInputChange.bind(null, cellInfo)}
                        value={cellValue}
                        tag={InputMask}
                    />
                ) : (<div>
                    {cellInfo.value}
                </div>)}
            </div>
        );
    }

    saveRowData(cellInfo) {
        this.setState({editContent: false}, () => console.log(cellInfo.original.generalComment))
        var projectId = this.props.projectId
        // this.props.getGeneralComments({projectId})
        axios.put(process.env.REACT_APP_API_URL + '/api/generalComment/', {
            _id: cellInfo.original._id,
            generalComment: cellInfo.original.generalComment,

        }, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            console.log(res)
            this.setState({
                editingRow: -1,
                isDirty: false
            })
            return res.data
        })
            .catch((error) => {
                ;
            })
    }

    toggleEditable(index) {
        console.log(index);
        const {isDirty} = this.state;
        if (!isDirty) {
            this.setState({
                editingRow: index,
                isDirty: true
            });
        } else {
            this.setState({
                editingRow: -1,
                isDirty: false
            })
        }
    }

    renderActiveButtons(row) {

        return (<div>
            <Button
                onClick={() => this.toggleEditable(row.index)}
                size="xs"
                className="btn-icon btn-neutral btn btn-info"
            ><i className="fa fa-edit"
            /></Button>
            <Button
                onClick={() => this.saveRowData(row)}
                size="xs"
                className="btn-icon btn-neutral btn btn-info"
            ><i className="fa fa-save"
            /></Button>
            <Button
                color="danger"
                size="sm"
                className="btn-icon btn-neutral btn btn-info"
                onClick={() => {
                    this.deleteGeneralCommentById(row.original._id)
                }
                }
            ><i
                className="fa fa-remove"
            />
            </Button>
        </div>)


    }


    render() {
        return (
            <>
                <div className="content">
                    <ReactTable
                        data={this.props.allGeneralCommentData}
                        columns={[
                            {
                                Header: this.props.t("General Comments Id"),
                                accessor: "_id",
                                show: false
                            },
                            {
                                Header: this.props.t("Previous General Comments"),
                                accessor: "generalComment",
                                // Cell: this.renderEditable
                            },
                            {
                                Header: this.props.t("Date"),
                                accessor: "date",
                                Cell: (props) => {
                                    //props.value will contain your date
                                    //you can convert your date here
                                    const custom_date = moment.utc(props.value).format('DD MMM YYYY')
                                    return <span>{custom_date}</span>
                                }
                            }
                        ]}
                        defaultPageSize={1}
                        pageSize={this.props.allGeneralCommentData.length}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        className="-striped -highlight primary-pagination"
                    />
                </div>
            </>
        );
    }
}

const AddGeneralCommentsPreviousTable = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConnectedAddGeneralCommentsPreviousTable));
export default AddGeneralCommentsPreviousTable;
