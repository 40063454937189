import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import classnames from 'classnames';
import {connect} from 'react-redux';
import {
    Row,
    Col, Input, Label, FormGroup, CardBody, Button, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import Select from "react-select"
import axios from "axios";
import {getUserRolesCanManage, userCan} from "../../../services/Authorization/authService";
import {withTranslation} from 'react-i18next';

class InvitationsForm extends Component {
    constructor() {
        super();
        this.state = this.stateObject();

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleCheckedLocations = this.handleCheckedLocations.bind(this)
        this.handleCheckedRoles = this.handleCheckedRoles.bind(this)
    }

    componentDidMount() {
        this.populateRolesList()
        this.populateDropDownCompany(this.props.companyTableData);
    }

    stateObject() {
        return {
            email: '',
            errors: {},
            roles: [],
            position: '',
            companies: [],
            companyFromAPI: [],
            rolesList: [],
            locations: [],
            companyLocations: [],
            companyLocationsForm: [],
            showAssignCompaniesForm: false,
            selectedCompanyPositions: [],
            activeFormTab: 'details',
        };
    }

    toggleActiveTabForm(tabId) {
        if (this.state.activeFormTab !== tabId) {
            this.setState({
                activeFormTab: tabId
            });
        }
    }

    updateCompaniesListOptions() {
        let companiesOptions = this.props.companyTableData;

        // if there's any assigned locations, so only show the selected company
        if (this.state.companyLocationsForm.length) {
            companiesOptions = companiesOptions.filter(c => c._id == this.state.companyLocationsForm[0].company._id)
        }

        this.populateDropDownCompany(
            companiesOptions
        )
    }

    setSelectedCompanyPositions() {
        if (!this.state.companies.length) {
            return [];
        }

        let positions = [];

        this.state.companies.forEach(company => {

            let companyObject = this.props.companyTableData.find(c => c._id == company.value)

            if (companyObject) {
                positions.push(...companyObject.positions)
            }

        })

        this.setState({
            selectedCompanyPositions: positions
        })
    }

    handleCheckedLocations(e, location, company) {
        if (e.target.checked) {
            this.state.locations.push({
                location,
                company: company
            });
        } else {
            this.state.locations = this.state.locations.filter(r => r.location._id !== location._id);
        }

        this.setState({
            locations: Array.from(new Set(this.state.locations))
        })
    }

    populateRolesList() {
        let user = this.props.auth.user;

        if (userCan(user, 'roles.manage')) {
            return this.setState({
                rolesList: this.props.rolesList
            })
        }

        this.setState({
            rolesList: getUserRolesCanManage(user)
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.companyTableData !== this.props.companyTableData) {
            this.populateDropDownCompany(this.props.companyTableData);
        }
    }

    populateDropDownCompany(data) {
        const options = data.map(company => {
            return {value: company._id, label: company.name}
        })

        this.setState({companyFromAPI: options})
    }

    componentWillReceiveProps(nextProps, {companyTableData, positionTableData}) {
        if (nextProps.errors) {
            this.setState({errors: nextProps.errors});
        }

        this.setState({
            ...this.state,
            companyTableData,
            positionTableData
        })
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    availableLocations(companiesIds) {
        let locations = [];

        companiesIds.forEach(companyId => {
            let foundCompany = this.props.companyTableData.find(c => c._id == companyId);
            if (foundCompany && foundCompany.locations) {

                const nonAddedLocations = foundCompany.locations.filter(l => {
                    return !this.state.companyLocationsForm.find(row => row.location._id == l._id)
                })

                locations.push({
                    company: foundCompany,
                    locations: nonAddedLocations
                })
            }
        })

        return locations;
    }

    assignLocations() {

        let companyLocationsForm = this.state.companyLocationsForm;

        companyLocationsForm.unshift(...this.state.locations);

        let companiesIds = new Set(this.state.companies.map(item => item.value));

        this.setState({
            companyLocationsForm: companyLocationsForm,
            companyLocations: this.availableLocations(companiesIds),
            locations: []
        }, () => this.updateCompaniesListOptions())
    }

    removeLocation(row) {

        let canBeUnassigned = row.location.can_be_unassigned;

        if (canBeUnassigned === undefined) {
            canBeUnassigned = true;
        }

        if (!canBeUnassigned) {
            return
        }

        let companyLocationsForm = this.state.companyLocationsForm;

        companyLocationsForm = companyLocationsForm.filter(item => {
            return !(item.company._id == row.company._id && item.location._id == row.location._id)
        })


        this.setState({
            companyLocationsForm: companyLocationsForm,
            locations: []
        }, () => {
            let companiesIds = new Set(this.state.companies.map(item => item.value));
            this.setState({
                companyLocations: this.availableLocations(companiesIds)
            })

            this.updateCompaniesListOptions();
        })
    }

    onSubmit(e) {
        e.preventDefault();

        let companies = [], locations = [];

        this.state.companyLocationsForm.forEach(row => {
            companies.push(row.company._id)
            locations.push(row.location._id)
        })

        const data = {
            email: this.state.email,
            companies: Array.from(new Set(companies)),
            positions: typeof this.state.position === 'object' ? [this.state.position.value] : [],
            locations: locations,
            roles: this.state.roles
        };

        axios.post(process.env.REACT_APP_API_URL + "/invitations", data, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.setState(this.stateObject())
            this.props.pushInvitation(res.data);
            this.props.toggleForm();
        }).catch(err => {
            this.setState({errors: err.response.data})
        })
    }

    handleCheckedRoles(e, role) {
        if (e.target.checked) {
            this.state.roles.push(role._id);
        } else {
            this.state.roles = this.state.roles.filter(r => r !== role._id);
        }

        this.setState({
            roles: Array.from(new Set(this.state.roles))
        })
    }

    render() {
        const {errors = {}} = this.state;
        const customStyles = {
            'padding-bottom': '20px',
        };
        return (
            <Row>
                <Col>
                    <CardBody>
                        <Button color="info" onClick={() => this.props.toggleForm()}>
                            {this.props.t('Close')}
                        </Button>
                        <form noValidate onSubmit={this.onSubmit}>
                            <Nav style={customStyles} tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: this.state.activeFormTab === 'details',
                                            'text-danger': Object.keys(errors).filter(k => !['locations', 'companies'].includes(k)).length
                                        })}
                                        onClick={() => {
                                            this.toggleActiveTabForm('details')
                                        }}
                                    >
                                        {this.props.t('Details')}
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                                active: this.state.activeFormTab === 'companies',
                                                'text-danger': errors.companies || errors.locations
                                            }
                                        )}
                                        onClick={() => {
                                            this.toggleActiveTabForm('companies')
                                        }}
                                    >
                                        {this.props.t('Company')}
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={this.state.activeFormTab}>
                                <TabPane tabId="details">

                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className={classnames('form-control form-control-lg', {
                                                'is-invalid': errors.email
                                            })}
                                            placeholder={this.props.t("Email Address")}
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.onChange}
                                        />
                                        {errors.email && (
                                            <div className="invalid-feedback">{errors.email}</div>
                                        )}
                                    </div>


                                    {<>
                                        <h4 className="m-0">{this.props.t('User Roles')}</h4>
                                        {this.state.rolesList.map((r, index) =>
                                            <Row key={`role_${r._id}`}>
                                                <Col>
                                                    <FormGroup className="d-inline mr-2"
                                                               key={`r_${index}`}
                                                               style={{"fontSize": "16px"}}
                                                    >
                                                        <Input
                                                            checked={this.state.roles.includes(r._id)}
                                                            id={r.name}
                                                            addon type="checkbox"
                                                            onChange={(e) => this.handleCheckedRoles(e, r)}
                                                        />
                                                        <Label check for={r.name} className="ml-1">
                                                            {r.label}
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        )}
                                        {errors.roles && (
                                            <div className="invalid-feedback d-block">{errors.roles}</div>
                                        )}
                                    </>}

                                </TabPane>
                                <TabPane tabId="companies">
                                    <Row>
                                        <Col>
                                            <a href={'#'} className={classnames('btn btn-success btn-sm')}
                                               onClick={(e) => {
                                                   e.preventDefault();
                                                   this.setState({showAssignCompaniesForm: !this.state.showAssignCompaniesForm})
                                               }}>

                                                {!this.state.showAssignCompaniesForm &&
                                                <i className={'fa fa-plus'}></i>}
                                                {this.state.showAssignCompaniesForm &&
                                                <i className={'fa fa-minus'}></i>}
                                                {this.props.t('Assign Company')}
                                            </a>
                                        </Col>
                                    </Row>


                                    {this.state.showAssignCompaniesForm && <>
                                        <FormGroup>
                                            <Select
                                                classNamePrefix="react-select"
                                                name="company"
                                                value={this.state.companies}
                                                onChange={companyOption => {
                                                    this.setState({
                                                        companies: [companyOption],
                                                        locations: []
                                                    }, () => {
                                                        let availableLocations = this.availableLocations(this.state.companies.map(c => c.value));

                                                        this.setState({
                                                            companyLocations: availableLocations,
                                                        })

                                                        this.setSelectedCompanyPositions();
                                                    })
                                                }
                                                }
                                                className={classnames('form-control react-select primary', {
                                                    'is-invalid': errors.companies
                                                })}
                                                options={this.state.companyFromAPI}
                                                placeholder={this.props.t('Company')}
                                            />
                                        </FormGroup>
                                        {this.state.selectedCompanyPositions.length ?
                                            <label className="m-0 d-block">{this.props.t('Position')}</label> : ''}

                                        <div className={'d-flex justify-content-start gap-2'}>
                                            {this.state.selectedCompanyPositions.map((position, index) =>
                                                <div className="form-check-radio mr-2" key={`pos_${index}`}>
                                                    <Label check key={`poslab_${index}`}>
                                                        <Input
                                                            defaultValue={position._id}
                                                            checked={this.state.position?.value == position._id}
                                                            id={position._id}
                                                            name="positions"
                                                            type="radio"
                                                            onChange={(e) => {
                                                                e.stopPropagation()
                                                                this.setState({
                                                                    position: {
                                                                        value: e.target.value,
                                                                        label: this.state.selectedCompanyPositions.find(s => s._id == e.target.value).name
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                        {position.name}
                                                        <span className="form-check-sign"/>
                                                    </Label>
                                                </div>
                                            )}
                                        </div>

                                        {this.state.companyLocations.length > 0 && <Row style={{
                                            maxHeight: "200px",
                                            overflowY: "scroll",
                                            overflowX: 'scroll',
                                        }}>
                                            <Col>
                                                <label className="m-0">{this.props.t('Locations')}</label>
                                                <br/>
                                                {this.state.companyLocations.map((group, index) =>
                                                    <>
                                                        <h6 key={index} style={{
                                                            'margin': '0',
                                                            'fontWeight': 'bold'
                                                        }}>{group.company.name}</h6>

                                                        <div className={'ml-3 mb-1'} key={`companyLocation_${index}`}>
                                                            {group.locations.map((c, index) => <Row key={`c_${index}`}>
                                                                <Col md={12} key={`location_${index}`}>
                                                                    <FormGroup className="d-inline mr-2"
                                                                               style={{"fontSize": "16px"}}
                                                                    >
                                                                        <Input
                                                                            checked={!!this.state.locations.find(_item => _item.location._id == c._id)}
                                                                            id={c._id}
                                                                            addon type="checkbox"
                                                                            onChange={(e) => this.handleCheckedLocations(e, c, group.company)}
                                                                        />
                                                                        <Label style={{
                                                                            "whiteSpace": "nowrap",
                                                                            'position': 'absolute'
                                                                        }}
                                                                               for={c._id} className="ml-1">
                                                                            {c.name} ({c.address})
                                                                        </Label>
                                                                    </FormGroup>

                                                                </Col>
                                                            </Row>)}
                                                        </div>
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                        }
                                        <Row>
                                            <Col>
                                                <a href={'#'} className={classnames('btn btn-success btn-sm', {
                                                    disabled: !this.state.locations.length
                                                })} onClick={() => {
                                                    this.assignLocations()
                                                }}>
                                                    <i className={'fa fa-plus'}></i> {this.props.t('Assign')}
                                                </a>
                                            </Col>
                                        </Row>

                                        <hr/>
                                    </>
                                    }

                                    <Row style={{'padding': '10px', 'fontSize': '12px'}}>
                                        <table className={'table table-stripe'}>
                                            <thead>
                                            <tr>
                                                <th style={{'fontSize': '12px'}}>{this.props.t('Company')}</th>
                                                <th style={{'fontSize': '12px'}}>{this.props.t('Location')}</th>
                                                <th style={{'fontSize': '12px'}}>{this.props.t('Position')}</th>
                                                <th style={{'fontSize': '12px'}}>{this.props.t('Actions')}</th>
                                            </tr>
                                            </thead>

                                            <tbody>


                                            {
                                                !this.state.companyLocationsForm.length &&
                                                <tr>
                                                    <td colSpan={4} className={'text-center'}>
                                                        {this.props.t('No Companies assigned yet')}!
                                                        {!this.state.showAssignCompaniesForm && <>
                                                            <br/>
                                                            <a href={'#'}
                                                               className={classnames('btn btn-success btn-sm')}
                                                               onClick={() => {
                                                                   this.setState({showAssignCompaniesForm: !this.state.showAssignCompaniesForm})
                                                               }}>{this.props.t('Click here to assign')} </a>
                                                        </>
                                                        }
                                                    </td>
                                                </tr>
                                            }

                                            {this.state.companyLocationsForm.map((row, index) =>
                                                <tr key={`tr_${index}`}>
                                                    <td>{row.company.name}</td>
                                                    <td>
                                                        {row.location.name}
                                                        <br/>
                                                        {row.location.address}
                                                    </td>

                                                    <td>
                                                        {this.state.position?.label}
                                                    </td>
                                                    <td>
                                                        {(row.location.can_be_unassigned === undefined ? true : row.location.can_be_unassigned) &&
                                                        <a href={'#'} onClick={() => this.removeLocation(row)}
                                                           className={'btn btn-danger btn-sm'}>
                                                            <i className={'fa fa-trash'}></i>
                                                        </a>
                                                        }
                                                    </td>
                                                </tr>
                                            )}

                                            </tbody>
                                        </table>
                                    </Row>

                                    {errors.locations && (
                                        <div className="invalid-feedback d-block">{errors.locations}</div>
                                    )}

                                    {errors.companies && (
                                        <div
                                            className="invalid-feedback d-block">{errors.companies}</div>
                                    )}

                                </TabPane>
                            </TabContent>

                            <Row>
                                <Col md={6}>
                                    <input type="submit" className="btn btn-info btn-block "/>
                                </Col>

                            </Row>
                        </form>
                    </CardBody>
                </Col>
            </Row>
        );
    }
}

InvitationsForm.propTypes = {
    InvitationsForm: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    loggedInUser: state.auth.user,
    rolesList: state.allOtherReducers.rolesList.filter(r => r.status === 'active'),
    projectId: state.allOtherReducers.projectId,
});

export default withTranslation()(connect(mapStateToProps)(withRouter(InvitationsForm)));
