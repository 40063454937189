import React from "react";
import axios from "axios";
import {Dock} from "react-dock"

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col, Button
} from "reactstrap";

import UserTable from "./UserTable"
import RegisterUserForm from "./RegisterUserForm"
import ChangePasswordForm from "views/forms/ChangePasswordForm"
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {userCant, userCan} from "../../../services/Authorization/authService";
import {withTranslation} from 'react-i18next';

function mapStateToProps(state) {
    return {
        project: state.allOtherReducers.project,
        projectId: state.allOtherReducers.projectId,
        projectData: state.allOtherReducers.projectData,
        reportDate: state.allOtherReducers.reportDate,
        loggedInUser: state.auth.user
    };
}

class UserDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userTableData: [],
            projectTableData: [],
            companyTableData: [],
            showUserForm: false
        };
        this.getSomeCompanies = this.getSomeCompanies.bind(this)

        // this.formSubmitSuccess=this.formSubmitSuccess.bind(this)
        this.getSomeUsers = this.getSomeUsers.bind(this)
        this.getSomeCompanies();
        this.toggleUserForm = this.toggleUserForm.bind(this)
        this.refreshUser = this.refreshUser.bind(this)
        this.pushUser = this.pushUser.bind(this)
        this.setEditingUser = this.setEditingUser.bind(this)
    }

    componentWillMount() {
        if (userCant(this.props.loggedInUser, 'users.index')) {
            return this.props.history.push('/admin/instructions')
        }

        this.getSomeUsers();

    }

    componentDidUpdate(prevProps) {

        if (this.props.projectId !== prevProps.projectId) {
            this.getSomeUsers()
            this.getSomeCompanies();
        }

        if (this.props.reportDate !== prevProps.reportDate) {
            this.getSomeUsers()
        }



    }
    getSomeCompanies() {

        if (userCant(this.props.loggedInUser, 'users.index')) {
            return
        }

        axios.get(process.env.REACT_APP_API_URL + "/api/company?projectId=" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({
                    companyTableData: res.data
                }, () => this.state.companyTableData);
                return res.data
            }).catch((error) => {
            ;
        })
    }
    getSomeUsers() {
        if (userCant(this.props.loggedInUser, 'users.index')) {
            return
        }

        let url = `${process.env.REACT_APP_API_URL}/api/user?projectId=${this.props.projectId}`;
    axios.get(url, {headers: {
      'Authorization': localStorage.jwtToken}
        })
            .then(res => {
                this.setState({
                    userTableData: res.data
                }, () => this.state.userTableData);
                return res.data
            }).catch((error) => {
            ;
        })
    }


    updateEmployeeTableData = (data) => {
        this.setState({userTableData: data}, () => this.state.userTableData)
    }

    toggleUserForm() {
        if (this.state.showUserForm) {
            this.setState({
                showUserForm: false,
                updateUser: null
            })
        } else {
            this.setState({
                showUserForm: true
            })
        }
    }

    refreshUser(user, customValue = null) {
        this.setState({
            userTableData: this.state.userTableData.map(originalUser => {

                if (originalUser._id === user._id) {

                    if (!customValue) {
                        return user;
                    }

                    //only update specific value from original user, not the full record
                    originalUser[customValue] = user[customValue]
                }

                return originalUser
            })
        })
    }

    pushUser(user) {
        this.state.userTableData.push(user);
    }

    setEditingUser(user) {
        if (userCant(this.props.loggedInUser, 'users.update')) {
            return;
        }
        this.setState({
            showUserForm: !!user,
            updateUser: user
        })
    }

    render() {

        if (userCant(this.props.loggedInUser, 'users.index')) {
            return
        }

        return (
            <>
                <div className="content">
                    <Dock
                        position='right'
                        size={window.innerWidth < 600 ? 1 : (window.innerWidth < 900 ? 0.5 : 0.3)}
                        isVisible={this.state.showUserForm}
                        duration={300}
                    >
                        {this.state.showUserForm && <RegisterUserForm
                            companyTableData={this.state.companyTableData}
                            toggleUserForm={this.toggleUserForm}
                            updateUser={this.state.updateUser}
                            refreshUser={this.refreshUser}
                            pushUser={this.pushUser}
                        />}
                    </Dock>

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    {userCan(this.props.loggedInUser, 'users.create') &&
                                    <Button color="info" type="submit"
                                            onClick={() => this.setState({showUserForm: !this.state.showUserForm})}>
                                        {this.props.t('Add User')}
                                    </Button>}
                                    <CardTitle tag="h4">{this.props.t('Users')}</CardTitle>

                                    {userCan(this.props.loggedInUser, 'users.index') && <UserTable
                                        userTableData={this.state.userTableData}
                                        getSomeUsers={this.getSomeUsers}
                                        refreshUser={this.refreshUser}
                                        setEditingUser={this.setEditingUser}
                                    />}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


export default withTranslation()(connect(mapStateToProps, null)(withRouter(UserDetails)));

